<template>
  <div>
    <JsDosSaveAlert />
    <GameCardTemplate :game_info="game_info">
      <template #canvas>
        <LoadingCanvas
          :bucket="bucket"
          :objectKey="objectKey"
          @url="handleUrl"
          v-if="!loaded"
        />

        <js-dos-canvas
          v-if="loaded"
          :binary_url="loadedBinaryUrl"
          :save_key="identifier"
          ref="jsdos_canvas"
        />
      </template>

      <template #controls>
        <screenshot-control @click="controls.takeScreenshot" />
        <info-control :href="info_web_url" />
        <keyboard-control @click="controls.toggleKeyboard" />
        <download-control :href="download_web_url" />
        <html-fullscreen-control @click="controls.toggleHtmlFullscreen" />
        <fullscreen-control @click="controls.toggleFullscreen" />
        <save-control @click="controls.save" />
      </template>
    </GameCardTemplate>
  </div>
</template>

<script>
import { fileSave } from "@/utils/fileSave";

import { GameCardTemplate } from "./GameCardCommon";
import JsDosSaveAlert from "./JsDosSaveAlert";
import { getLogger } from "@/utils/log";

import game_info_mixin from "@/mixins/game_info_mixin";
import { JsDosCanvas, LoadingCanvas } from "@/components/GameCanvas";
import {
  HtmlFullscreenControl,
  FullscreenControl,
  DownloadControl,
  KeyboardControl,
  ScreenshotControl,
  InfoControl,
  SaveControl,
} from "@/components/controls";

export default {
  name: "JsDosGameCard",
  props: ["game_info"],
  mixins: [game_info_mixin],
  data: function () {
    return {
      loadedBinaryUrl: "",
    };
  },
  components: {
    GameCardTemplate,
    JsDosCanvas,
    HtmlFullscreenControl,
    FullscreenControl,
    DownloadControl,
    KeyboardControl,
    InfoControl,
    SaveControl,
    ScreenshotControl,
    JsDosSaveAlert,
    LoadingCanvas,
  },
  mounted() {
    // If FireFox
    if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
      this.loadedBinaryUrl = this.jsdos_binary_url
    }
  },
  computed: {
    loaded: function () {
      return this.loadedBinaryUrl != "";
    },
    canvas: function () {
      if (this.loaded) {
        this.logger.log("JsDos Canvas Loaded");
        return this.$refs.jsdos_canvas;
      } else {
        this.logger.warn("JsDos Canvas Not Running");
        this.logger.warn("Use fake canvas for save");
        // A fake canvas for save
        return {
          controls: {
            save: async () => {},
          }
        }
      }
    },
    controls: function () {
      return {
        toggleKeyboard: () => this.canvas.controls.toggleKeyboard(),
        toggleHtmlFullscreen: () => this.canvas.controls.toggleHtmlFullscreen(),
        toggleFullscreen: () => this.canvas.controls.toggleFullscreen(),
        save: () => this.canvas.controls.save(),
        takeScreenshot: () => this.takeScreenshot(),
      };
    },
    bucket: function () {
      return "dos-bin";
    },
    objectKey: function () {
      return `${this.identifier}.jsdos.zip`;
    },
    logger: function () {
      return getLogger(["backend", "jsdos"]);
    },
  },
  methods: {
    handleUrl: function (url) {
      this.logger.log(`Loaded binary url: ${url}`);
      this.loadedBinaryUrl = url;
    },
    takeScreenshot: async function () {
      const blob = await this.canvas.controls.getScreenshotBlob()
      const fileName = `${this.identifier}_screenshot.png`;

      await fileSave(blob, {
        fileName
      })
    },
  },
};
</script>
