<template>
  <div
    id="jsdos-container"
    :class="{
      'html-fullscreen': isFullscreen,
      'html-fullscreen-keyboard': showKeyboard && isFullscreen,
    }"
  >
    <div id="jsdos" />
  </div>
</template>

<script>
import {
  startUpEmulator,
  saveToDB,
} from "@/utils/js-dos";
import disableScroll from "@/utils/disableScroll";
import disableKeys from "@/utils/disableKeys";

export default {
  name: "JsDosPlayer",
  props: ["binary_url", "save_key", "isFullscreen", "showKeyboard"],
  data: function () {
    return {
      saving: false,
      running: false,
      last_save_time: Date.now(),
      isPause: false,
      isMute: false,
    };
  },
  mounted: function () {
    if (navigator.userAgent.includes("prerender")) {
      return;
    }
    this.startUpJsDos();
  },
  methods: {
    notyf: function () {
      return {
        success: (message) => {
          const text = message.message ? message.message : message;

          this.$bvToast.toast(`操作成功。${text}`, {
            title: "操作成功✔",
            autoHideDelay: 5000,
            solid: true,
            variant: "success",
          });
        },
        error: (message) => {
          const text = message.message ? message.message : message;

          this.$bvToast.toast(`发生错误。${text}`, {
            title: "发生错误❌",
            noAutoHide: true,
            solid: true,
            variant: "danger",
          });
        },
      };
    },
    startUpJsDos: async function () {
      try {
        const dom = document.getElementById("jsdos");
        const config = {
          notyf: this.notyf(),
        };
        const jsdosInstance = await startUpEmulator(
          dom,
          this.binary_url,
          this.save_key,
          config
        );
        this.jsdosInstance = jsdosInstance;
        window.jsdosInstance = jsdosInstance;

        this.running = true;

        // prevent scrolling
        disableScroll();

        // prevent keys
        disableKeys();

        // Use current time as last save time
        this.updateLastSaveTime();
        // Don't close if not saved
        window.addEventListener("beforeunload", this.beforeunload);
      } catch (e) {
        this.errorHandler(e, "启动游戏");
      }
    },
    save: async function () {
      try {
        this.runningCheck();

        if (this.saving) {
          // Already saving, skip.
          return;
        }

        const saving_toast_id = `saving_toast_${Date.now()}`;
        this.$bvToast.toast(`正在保存进度中...`, {
          title: "正在保存中",
          autoHideDelay: 5000,
          solid: true,
          id: saving_toast_id,
          variant: "warning",
        });
        this.saving = true;
        const { save_length } = await saveToDB(
          this.jsdosInstance.ci,
          this.save_key
        );
        this.$bvToast.hide(saving_toast_id);
        await this.$nextTick();
        this.$bvToast.toast(`保存进度成功。存档大小：${save_length} bytes`, {
          title: "保存进度成功✔",
          autoHideDelay: 5000,
          solid: true,
          variant: "success",
        });
        this.updateLastSaveTime();
      } catch (e) {
        this.errorHandler(e, "保存进度");
      } finally {
        this.saving = false;
      }
    },
    getScreenshotBlob: async function () {
      try {
        this.runningCheck();

        const imageData = await this.jsdosInstance.ci.screenshot();
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        ctx.canvas.width = imageData.width;
        ctx.canvas.height = imageData.height;
        ctx.putImageData(imageData, 0, 0);

        const blob = await new Promise((resolve, reject) => {
          canvas.toBlob((blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error("Failed to get blob"));
            }
          });
        });

        canvas.remove();
        return blob;
      } catch (e) {
        this.errorHandler(e, "获取截图");
        return undefined;
      }
    },
    // METHOD: request fullscreen for jsdos canvas
    toggleFullscreen: function () {
      try {
        this.runningCheck();

        this.jsdosInstance.player.layers.toggleFullscreen();
      } catch (e) {
        this.errorHandler(e, "全屏");
      }
    },
    togglePause: function () {
      try {
        this.runningCheck();
        if (this.isPause) {
          this.jsdosInstance.ci.resume();
        } else {
          this.jsdosInstance.ci.pause();
        }
      } catch (e) {
        this.errorHandler(e, "暂停");
      }
    },
    toggleMute: function () {
      try {
        this.runningCheck();

        if (this.isMute) {
          this.jsdosInstance.ci.unmute();
        } else {
          this.jsdosInstance.ci.mute();
        }
      } catch (e) {
        this.errorHandler(e, "静音");
      }
    },
    // METHOD: beforeunload callback
    beforeunload: function (event) {
      //  If the game is not running, just close the window.
      if (!this.running) {
        return;
      }

      if (window.disable_beforeunload) {
        console.log("Disable beforeunload pause");
        return;
      }

      console.log(`Last save time: ${this.last_save_time}`);

      // If since last save is less than 10 seconds
      const current_time = Date.now();
      const save_stale = current_time - this.last_save_time > 30 * 1000;

      if (save_stale) {
        event.preventDefault();
        event.returnValue = "";
        this.$bvToast.toast(
          "进度保存了吗？ 1. 游戏中保存 2. 点击控制栏的保存按钮",
          {
            title: "保存进度 💾",
            autoHideDelay: 5000,
            solid: true,
            variant: "warning",
          }
        );
        this.save();
      }
    },
    // Method: update last save time
    updateLastSaveTime: function () {
      this.last_save_time = Date.now();
    },
    runningCheck: function () {
      if (!this.running) {
        throw new Error("游戏尚未运行");
      }
    },
    errorHandler: function (error, actionName_) {
      const actionName = actionName_ ?? "操作";

      console.error(`${actionName} failed`);
      console.error(error);

      this.$bvToast.toast(`${actionName}失败。错误信息：${error}`, {
        title: `${actionName}失败❌`,
        autoHideDelay: 5000,
        solid: true,
        variant: "danger",
      });
    },
  },
};
</script>

<style lang="scss">
#jsdos-container {
  @import "emulators-ui/dist/emulators-ui";

  .emulator {
    // To fit in the container
    &-canvas {
      height: 100% !important;
      width: 100% !important;
      object-fit: contain !important;
      left: auto !important;
      top: auto !important;
      image-rendering: auto !important;
    }

    // Ignore js-dos ascii logo
    //         _                __
    //        (_)____      ____/ /___  _____ _________  ____ ___
    //       / / ___/_____/ __  / __ \/ ___// ___/ __ \/ __ `__ \
    //      / (__  )_____/ /_/ / /_/ (__  )/ /__/ /_/ / / / / / /
    //   __/ /____/      \__,_/\____/____(_)___/\____/_/ /_/ /_/
    //  /___/
    &-loading-pre-1 {
      display: none;
    }

    &-loading-pre-2 {
      margin: 0 !important;
      font-family: sans-serif !important;
      text-transform: initial !important;
      font-weight: initial !important;
      color: #838383 !important;
      overflow: visible !important;

      &::before {
        content: "";
        display: block;
        margin: 0 auto 10px auto;
        width: 5em;
        height: 5em;
        background-size: contain;
        background-image: url("~@/assets/img/emularity_splash_image.svg");
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    &-loader {
      width: 2em !important;
      height: 2em !important;
    }
  }

  &.html-fullscreen-keyboard > #jsdos {
    bottom: 275px !important;
    height: calc(100% - (275px)) !important;
  }

  &.html-fullscreen > #jsdos {
    position: fixed !important;
    left: 0px;
    bottom: 0px;
    height: 100%;
    width: 100%;
    z-index: 2000;
    object-fit: contain;
  }
}
</style>
