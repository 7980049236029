<template>
  <span
    @click="$emit('click')"
    tabindex="0"
    role="button"
  >
    <ArchiveIcon id="tooltip-game-files" />
    <b-tooltip target="tooltip-game-files" triggers="hover">
      打开游戏文件夹
    </b-tooltip>
  </span>
</template>

<script>
import { ArchiveIcon } from "vue-feather-icons";
import { BTooltip } from "bootstrap-vue";

export default {
  name: "GameFilesControl",
  components: {
    ArchiveIcon,
    BTooltip,
  },
};
</script>
