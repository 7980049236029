<template>
  <div>
    <b-progress :max="100">
      <b-progress-bar
        :value="percentage"
        :label="formattedProgressBarText"
        :variant="barVariant"
      >
      </b-progress-bar>
    </b-progress>
  </div>
</template>

<script>
import filesize from "filesize";
import { BProgress, BProgressBar } from "bootstrap-vue";

export default {
  name: "ProgressShow",
  props: ["downloadProgress"],
  components: {
    BProgress,
    BProgressBar,
  },
  computed: {
    formattedCurrentLength: function () {
      return filesize(this.downloadProgress.currentLength, { round: 2 });
    },
    formattedTotalLength: function () {
      return filesize(this.downloadProgress.totalLength, { round: 2 });
    },
    formattedProgress: function () {
      return `${this.formattedCurrentLength} / ${this.formattedTotalLength}`;
    },
    formattedProgressBarText: function () {
      if (this.downloadProgress.status == "downloading") {
        return `${this.formattedProgress}`;
      }
      if (this.downloadProgress.status == "error") {
        return `下载出错 ${this.formattedProgress}`;
      }
      if (this.downloadProgress.status == "success") {
        return `下载成功 ${this.formattedProgress}`;
      }
      if (this.downloadProgress.status == "extracting") {
        return `解压中 ${this.formattedProgress}`;
      }
      return ""
    },
    percentage: function () {
      if (this.downloadProgress.totalLength == 0) {
        return 0;
      } else {
        return Math.round(
          (this.downloadProgress.currentLength /
            this.downloadProgress.totalLength) *
            100
        );
      }
    },
    barVariant: function () {
      if (this.downloadProgress.status == "downloading") {
        return "primary";
      }
      if (this.downloadProgress.status == "error") {
        return "danger";
      }
      if (this.downloadProgress.status == "success") {
        return "success";
      }
      if (this.downloadProgress.status == "extracting") {
          return "info"
      }
      return "primary";
    },
  },
};
</script>
