<template>
  <span
    @click="$emit('click')"
    tabindex="0"
    role="button"
  >
    <FileTextIcon id="tooltip-config-file" />
    <b-tooltip target="tooltip-config-file" triggers="hover">
      打开 dosbox.conf
    </b-tooltip>
  </span>
</template>

<script>
import { FileTextIcon } from "vue-feather-icons";
import { BTooltip } from "bootstrap-vue";

export default {
  name: "DosBoxConfigControl",
  components: {
    FileTextIcon,
    BTooltip,
  },
};
</script>
