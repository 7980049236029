<template>
  <span
    @click="$emit('click')"
    tabindex="0"
    role="button"
  >
    <maximize-icon id="tooltip-maximize" />
    <b-tooltip target="tooltip-maximize" triggers="hover"> 网页全屏 </b-tooltip>
  </span>
</template>

<script>
import { MaximizeIcon } from "vue-feather-icons";
import { BTooltip } from "bootstrap-vue";

export default {
  name: "HtmlFullscreenControl",
  components: {
    MaximizeIcon,
    BTooltip,
  },
};
</script>
