<template>
  <div>
    <emularity-player
      :binary_url="binary_url"
      :drive_type="drive_type"
      :save_key="save_key"
      :executable="executable"
      :img="img"
      :isFullscreen="isFullscreen"
      :showKeyboard="showKeyboard"
      ref="emularityPlayer"
    />

    <exit-fullscreen-button
      :isFullscreen="isFullscreen"
      :showKeyboard="showKeyboard"
      @click="htmlFullscreen"
    />

    <game-keyboard
      :showKeyboard="showKeyboard"
      @closeKeyboard="toggleKeyboard"
    />
  </div>
</template>

<script>
import { ExitFullscreenButton, GameKeyboard } from "../CanvasCommon";
import EmularityPlayer from "./EmularityPlayer";

export default {
  data: function () {
    return {
      isFullscreen: false,
      showKeyboard: false,
    };
  },
  name: "EmularityCanvas",
  props: ["binary_url", "drive_type", "save_key", "executable", "img"],
  components: {
    GameKeyboard,
    ExitFullscreenButton,
    EmularityPlayer,
  },
  methods: {
    htmlFullscreen: function () {
      try {
        if (
          !this.$refs.emularityPlayer.isRunning ||
          document.getElementById("canvas").height === 0
        ) {
          throw new Error();
        }

        this.isFullscreen = !this.isFullscreen;
      } catch (e) {
        this.$bvToast.toast(`请在游戏运行后进行全屏操作`, {
          title: "游戏尚未启动❌",
          autoHideDelay: 5000,
          solid: true,
          variant: "warning",
        });
      }
    },
    fullScreen: function () {
      try {
        this.$refs.emularityPlayer.emulator.requestFullScreen();
      } catch (e) {
        this.$bvToast.toast(`请在游戏运行后进行全屏操作`, {
          title: "游戏尚未启动❌",
          autoHideDelay: 5000,
          solid: true,
          variant: "warning",
        });
      }
    },
    toggleKeyboard() {
      this.showKeyboard = !this.showKeyboard;
      if (this.showKeyboard) {
        this.htmlFullscreen();
      }
    },
    takeScreenshot: async function() {
      const canvas = document.getElementById("canvas");
      const blob = await new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error("blob is null"));
          }
        })
      })
      return blob;
    },
  },
};
</script>
