<template>
  <GameCardTemplate :game_info="game_info">
    <template #canvas>
      <LoadingCanvas
        :bucket="bucket"
        :objectKey="objectKey"
        @url="handleUrl"
        v-if="!loaded"
      />

      <emularity-canvas
        v-if="loaded"
        :binary_url="loadedBinaryUrl"
        :drive_type="drive_type"
        :save_key="identifier"
        :executable="executable"
        :img="img"
        ref="gameCanvas"
      />
    </template>

    <template #controls>
      <screenshot-control @click="takeScreenshot" />
      <info-control :href="info_web_url" />
      <keyboard-control @click="toggleKeyboard" />
      <download-control :href="download_web_url" />
      <html-fullscreen-control @click="htmlFullscreen" />
      <fullscreen-control @click="fullScreen" />
    </template>
  </GameCardTemplate>
</template>

<script>
import { fileSave } from "@/utils/fileSave";
import { GameCardTemplate } from "./GameCardCommon";
import { getLogger } from "@/utils/log";

import game_info_mixin from "@/mixins/game_info_mixin";
import { EmularityCanvas, LoadingCanvas } from "@/components/GameCanvas";
import {
  HtmlFullscreenControl,
  FullscreenControl,
  DownloadControl,
  KeyboardControl,
  ScreenshotControl,
  InfoControl,
} from "@/components/controls";

export default {
  name: "EmularityGameCard",
  props: ["game_info"],
  mixins: [game_info_mixin],
  data: function () {
    return {
      loadedBinaryUrl: "",
    };
  },
  mounted() {
    // If FireFox
    if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
      this.loadedBinaryUrl = this.binary_url
    }
  },
  computed: {
    loaded: function () {
      return this.loadedBinaryUrl != "";
    },
    bucket: function () {
      return "dos-bin";
    },
    objectKey: function () {
      return `${this.identifier}.zip`;
    },
    logger: function () {
      return getLogger(["backend", "emularity"]);
    },
  },
  components: {
    HtmlFullscreenControl,
    FullscreenControl,
    DownloadControl,
    KeyboardControl,
    InfoControl,
    EmularityCanvas,
    ScreenshotControl,
    GameCardTemplate,
    LoadingCanvas,
  },
  methods: {
    toggleKeyboard() {
      this.$refs.gameCanvas.toggleKeyboard();
    },
    htmlFullscreen() {
      this.$refs.gameCanvas.htmlFullscreen();
    },
    fullScreen() {
      this.$refs.gameCanvas.fullScreen();
    },
    takeScreenshot: async function () {
      const blob = await this.$refs.gameCanvas.takeScreenshot();
      const fileName = `${this.identifier}_screenshot.png`;
      await fileSave(blob, {
        fileName,
      });
    },
    handleUrl: function (url) {
      this.logger.log(`Loaded binary url: ${url}`);
      this.loadedBinaryUrl = url;
    },
  },
};
</script>
