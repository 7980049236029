<template>
  <SimpleKeyboard
    v-if="loadedKeyboard"
    v-show="showKeyboard"
    @onKeyPress="onKeyPress"
    @onKeyReleased="onKeyReleased"
    @closeKeyboard="$emit('closeKeyboard')"
  />
</template>

<script>
const simpleKeyboardPromise = import(
  /* webpackChunkName: "simpleKeyboard" */ "@/components/SimpleKeyboard"
);

export default {
  name: "GameKeyboard",
  props: ["showKeyboard"],
  data: function () {
    return {
      loadedKeyboard: false,
    };
  },
  components: {
    SimpleKeyboard: () => simpleKeyboardPromise,
  },
  watch: {
    showKeyboard: function () {
      // initial load
      this.loadedKeyboard = true;

      if (this.showKeyboard) {
        // if show keyboard, make body unselectable
        document.body.classList.add("unselectable");
      } else {
        document.body.classList.remove("unselectable");
      }
    },
  },
  methods: {
    onKeyPress(ke) {
      document.dispatchEvent(ke);
    },
    onKeyReleased(ke) {
      document.dispatchEvent(ke);
    },
  },
};
</script>


<style>
body.unselectable {
  user-select: none;
}
</style>