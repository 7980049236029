<template>
  <div>
    <GameCardTemplate :game_info="game_info">
      <template #canvas>
        <LogoPlaceholder v-if="!dosboxRunning">
          <ProgressShow
            :downloadProgress="dosboxDownloadProgress"
            v-if="dosboxDownloadProgress.status != 'not-started'"
            class="mb-2 mr-3 ml-3"
          />
          <ProgressShow
            :downloadProgress="gameDownloadProgress"
            v-if="gameDownloadProgress.status != 'not-started'"
            class="mb-2 mr-3 ml-3"
          />
          <ClickToRun @click="launchGame" v-if="gameReady" />
        </LogoPlaceholder>
        <ConsoleOutput :logs="dosboxLog" v-if="dosboxRunning" />
      </template>
      <template #controls>
        <DosBoxConfigControl
          @click="openDosBoxConfig"
          v-if="gameArchiveExist"
        />
        <GameFilesControl @click="openGameFolder" v-if="gameArchiveExist" />
        <RestartControl @click="restartGame" v-if="dosboxRunning" />
      </template>
    </GameCardTemplate>
  </div>
</template>

<script>
import { GameCardTemplate } from "../GameCardCommon";
import ConsoleOutput from "./ConsoleOutput";
import ProgressShow from "./ProgressShow";
import LogoPlaceholder from "./LogoPlaceholder";
import ClickToRun from "./ClickToRun";

import game_info_mixin from "@/mixins/game_info_mixin";
import { v4 as uuidv4 } from "uuid";

import { getBestHost } from "@/utils/binary-host";

import {
  RestartControl,
  GameFilesControl,
  DosBoxConfigControl,
} from "@/components/controls";

export default {
  name: "NativeDOSBoxGameCard",
  props: ["game_info"],
  mixins: [game_info_mixin],
  data: function () {
    return {
      dosboxLog: [],
      messageId: uuidv4(),
      gameDownloadProgress: {
        currentLength: 0,
        totalLength: 0,
        status: "not-started",
      },
      dosboxDownloadProgress: {
        currentLength: 0,
        totalLength: 0,
        status: "not-started",
      },
      dosboxRunning: false,
    };
  },
  components: {
    GameCardTemplate,
    ConsoleOutput,
    ProgressShow,
    LogoPlaceholder,
    ClickToRun,
    RestartControl,
    GameFilesControl,
    DosBoxConfigControl,
  },
  computed: {
    logEventName: function () {
      return `dosbox-log:${this.messageId}`;
    },
    closeEventName: function () {
      return `close-dosbox:${this.messageId}`;
    },
    ipcRenderer: function () {
      const ipcRenderer = window.ipcRenderer;
      return ipcRenderer;
    },
    gameArchiveInitExist: function () {
      return this.ipcRenderer.sendSync("gameArchive-exist", {
          identifier: this.identifier,
        })
    },
    gameArchiveExist: function () {
      return (
        this.gameArchiveInitExist || this.gameDownloadProgress.status == "success"
      );
    },
    dosboxInitExist: function () {
      return this.ipcRenderer.sendSync("dosbox-exist")
    },
    dosboxExist: function () {
      return (
        this.dosboxInitExist || this.dosboxDownloadProgress.status == "success"
      );
    },
    gameReady: function () {
      return this.gameArchiveExist && this.dosboxExist;
    },
  },
  mounted: async function () {
    if (!this.dosboxInitExist) {
      console.log("DosBox not exist");
      this.downloadDosBox();
    }

    if (!this.gameArchiveInitExist) {
      console.log("Game Archive not exist");
      await this.downloadGame();
    }
  },
  methods: {
    downloadDosBox: async function () {
      // Avoid double downloading
      if (this.dosboxDownloadProgress.status != "not-started") {
        return;
      }

      const eventName = "download-dosbox";
      const progressName = `download-dosbox-progress:${this.messageId}`;

      const promise = new Promise((resolve, reject) => {
        this.dosboxDownloadProgress.status = "downloading";

        this.ipcRenderer.on(progressName, (event, arg) => {
          if (arg.type == "totalLength") {
            this.dosboxDownloadProgress.totalLength = arg.totalLength;
          }
          if (arg.type == "currentLength") {
            this.dosboxDownloadProgress.currentLength = arg.currentLength;
          }
          if (arg.type == "status") {
            this.dosboxDownloadProgress.status = arg.status;
            if (this.dosboxDownloadProgress.status == "success") {
              resolve();
            }
            if (this.dosboxDownloadProgress.status == "error") {
              reject(arg.message);
            }
          }
        });

        this.ipcRenderer.send(eventName, {
          messageId: this.messageId,
        });
      });

      await promise;
    },
    downloadGame: async function () {
      // Avoid double downloading
      if (this.gameDownloadProgress.status != "not-started") {
        return;
      }

      const eventName = "download-gameArchive";
      const progressName = `download-gameArchive-progress:${this.messageId}`;
      const prefix = await getBestHost();

      const promise = new Promise((resolve, reject) => {
        this.gameDownloadProgress.status = "downloading";

        this.ipcRenderer.on(progressName, (event, arg) => {
          if (arg.type == "totalLength") {
            this.gameDownloadProgress.totalLength = arg.totalLength;
          }
          if (arg.type == "currentLength") {
            this.gameDownloadProgress.currentLength = arg.currentLength;
          }
          if (arg.type == "status") {
            this.gameDownloadProgress.status = arg.status;
            if (this.gameDownloadProgress.status == "success") {
              resolve();
            }
            if (this.gameDownloadProgress.status == "error") {
              reject(arg.message);
            }
          }
        });

        this.ipcRenderer.send(eventName, {
          messageId: this.messageId,
          gameIdentifier: this.identifier,
          binaryPrefix: prefix,
        });
      });

      await promise;
    },
    launchGame() {
      this.ipcRenderer.on(this.logEventName, (event, arg) => {
        this.dosboxLog.push(arg);
      });
      this.ipcRenderer.send("launch-dosbox", {
        messageId: this.messageId,
        gameIdentifier: this.identifier,
      });
      this.dosboxRunning = true;
    },
    closeGame() {
      this.ipcRenderer.send(this.closeEventName);
      this.ipcRenderer.removeAllListeners(this.logEventName);
    },
    restartGame() {
      this.closeGame();
      this.launchGame();
    },
    openGameFolder() {
      this.ipcRenderer.send("open-gameDataFolder", {
        messageId: this.messageId,
        identifier: this.identifier,
      });
    },
    openDosBoxConfig() {
      this.ipcRenderer.send("open-dosboxConfig", {
        messageId: this.messageId,
        identifier: this.identifier,
      });
    },
  },
  destroyed() {
    this.closeGame();
  },
};
</script>
