<template>
  <div>
    <js-dos-player
      :binary_url="binary_url"
      :save_key="save_key"
      :isFullscreen="isFullscreen"
      :showKeyboard="showKeyboard"
      ref="player"
    />

    <exit-fullscreen-button
      :isFullscreen="isFullscreen"
      :showKeyboard="showKeyboard"
      @click="toggleHtmlFullscreen"
    />

    <game-keyboard
      :showKeyboard="showKeyboard"
      @closeKeyboard="toggleKeyboard"
    />
  </div>
</template>

<script>
import JsDosPlayer from "./JsDosPlayer.vue";
import { ExitFullscreenButton, GameKeyboard } from "../CanvasCommon";
import { getLogger } from "@/utils/log";
import isMobile from "@/utils/isMobile";

export default {
  data: function () {
    return {
      isFullscreen: false,
      showKeyboard: false,
    };
  },
  components: {
    GameKeyboard,
    JsDosPlayer,
    ExitFullscreenButton,
  },
  name: "JsDosCanvas",
  props: ["binary_url", "save_key"],
  computed: {
    logger: function () {
      return getLogger(["backend", "jsdos"]);
    },
    player: function () {
      return this.$refs.player;
    },
    controls: function () {
      return {
        save: this.player.save,
        toggleHtmlFullscreen: this.toggleHtmlFullscreen,
        // If mobile, don't use Canvas fullscren, can't exit.
        toggleFullscreen: isMobile
          ? this.toggleHtmlFullscreen
          : this.player.toggleFullscreen,
        toggleKeyboard: this.toggleKeyboard,
        getScreenshotBlob: this.player.getScreenshotBlob,
      };
    },
  },
  methods: {
    toggleHtmlFullscreen: function () {
      this.isFullscreen = !this.isFullscreen;
    },
    toggleKeyboard() {
      this.showKeyboard = !this.showKeyboard;
      if (this.showKeyboard) {
        this.isFullscreen = true;
      }
    },
  },
};
</script>
