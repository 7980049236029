<template>
  <span @click="$emit('click')" tabindex="0" role="button">
    <camera-icon id="tooltip-camera" />
    <b-tooltip target="tooltip-camera" triggers="hover"> 截图 </b-tooltip>
  </span>
</template>

<script>
import { CameraIcon } from "vue-feather-icons";
import { BTooltip } from "bootstrap-vue";

export default {
  name: "SaveControl",
  components: {
    CameraIcon,
    BTooltip,
  },
};
</script>
