<template>
  <span
    @click="$emit('click')"
    tabindex="0"
    role="button"
  >
    <save-icon id="tooltip-save" />
    <b-tooltip target="tooltip-save" triggers="hover"> 保存进度 </b-tooltip>
  </span>
</template>

<script>
import { SaveIcon } from "vue-feather-icons";
import { BTooltip } from "bootstrap-vue";

export default {
  name: "SaveControl",
  components: {
    SaveIcon,
    BTooltip,
  },
};
</script>
