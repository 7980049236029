<template>
  <div class="background">
      <div class="logo"></div>
      <slot></slot>
  </div>
</template>

<script>
export default {
  name: "LogoPlaceholder",
};
</script>

<style scoped>
.background {
  background: black;
}

.logo {
  background-size: contain;
  background-image: url("~@/assets/img/emularity_splash_image.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-origin: content-box;
  width: 100%;
  height: 200px;
  padding: 2em
}
</style>