<template>
  <b-alert
    :show="dismissCountDown"
    dismissible
    @dismissed="dismissCountDown = 0"
    @dismiss-count-down="countDownChanged"
    data-nosnippet
    variant="success"
  >
    请在游戏中存档后，再点击控制栏中的保存按钮 <SaveIcon /> 进行保存。
  </b-alert>
</template>

<script>
import { SaveIcon } from "vue-feather-icons";

export default {
  data: function () {
    return {
      dismissCountDown: 10,
    };
  },
  components: {
    SaveIcon,
  },
  name: "SaveAlert",
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
  },
};
</script>
