<template>
  <b-card class="mb-3" no-body>
    <canvas
      v-if="!ready"
      class="game-canvas-placeholder"
      oncontextmenu="event.preventDefault()"
    />
    <emularity-canvas
      v-if="ready"
      :binary_url="binary_url"
      :drive_type="drive_type"
      :save_key="save_key"
      :executable="executable"
      :img="false"
      ref="gameCanvas"
    />

    <!-- Game title and controls -->
    <b-card-body>
      <!-- Title -->
      <h5 class="card-title game-title">
        自定义游戏
        <b-badge class="ml-1" variant="secondary">Beta</b-badge>
      </h5>
      <!-- Controls -->
      <div class="card-controls">
        <keyboard-control @click="toggleKeyboard" />
        <html-fullscreen-control @click="htmlFullscreen" />
        <fullscreen-control @click="fullScreen" />
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { EmularityCanvas } from "@/components/GameCanvas";
import { BBadge } from "bootstrap-vue";
import {
  HtmlFullscreenControl,
  FullscreenControl,
  KeyboardControl,
} from "@/components/controls";

export default {
  name: "GameCard",
  props: ["binary_url", "drive_type", "save_key", "executable", "ready"],
  components: {
    HtmlFullscreenControl,
    FullscreenControl,
    KeyboardControl,
    EmularityCanvas,
    BBadge,
  },
  methods: {
    toggleKeyboard() {
      if (this.$refs.gameCanvas) {
        this.$refs.gameCanvas.toggleKeyboard();
      } else {
        this.$bvToast.toast(`请在游戏运行后进行键盘操作`, {
          title: "游戏尚未启动❌",
          autoHideDelay: 5000,
        });
      }
    },
    htmlFullscreen() {
      if (this.$refs.gameCanvas) {
        this.$refs.gameCanvas.htmlFullscreen();
      } else {
        this.$bvToast.toast(`请在游戏运行后进行全屏操作`, {
          title: "游戏尚未启动❌",
          autoHideDelay: 5000,
        });
      }
    },
    fullScreen() {
      if (this.$refs.gameCanvas) {
        this.$refs.gameCanvas.fullScreen();
      } else {
        this.$bvToast.toast(`请在游戏运行后进行全屏操作`, {
          title: "游戏尚未启动❌",
          autoHideDelay: 5000,
        });
      }
    },
  },
};
</script>

<style scoped>
.game-canvas-placeholder {
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
  display: block;
  border: 0px none;
  background-color: #222;
  width: 100%;
  height: 100%;
  object-fit: contain;
  image-rendering: auto !important;
}

.game-title {
  display: inline-block;
}
</style>

<style lang="scss" scoped>
.card-controls {
  float: right;
  vertical-align: top;

  & > * {
    margin-left: 0.3rem;
  }
}
</style>
