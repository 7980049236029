<template>
  <LogoPlaceholder>
    <ProgressShow :downloadProgress="downloadProgress" class="pb-3 pr-3 pl-3" />
  </LogoPlaceholder>
</template>

<script>
import { getBlob, statusType, getOriginalWebUrl } from "@/utils/objectStorage";
import { getLogger } from "@/utils/log";
import isMobile from "@/utils/isMobile";
import LogoPlaceholder from "./LogoPlaceholder.vue";
import ProgressShow from "@/components/ObjectStorage/ProgressShow.vue";

export default {
  data: function () {
    return {
      downloadProgress: {
        currentLength: 0,
        totalLength: 0,
        downloadSpeed: 0,
        status: statusType.unstarted,
      },
    };
  },
  components: {
    LogoPlaceholder,
    ProgressShow,
  },
  name: "LoadingCanvas",
  props: ["bucket", "objectKey"],
  mounted() {
    this.handleBinary();
  },
  computed: {
    logger: function () {
      return getLogger(["objectStorage"]);
    },
  },
  methods: {
    handleBinary: async function () {
      try {
        const blob = await getBlob(
          this.bucket,
          this.objectKey,
          {
            webTorrent: true,
            cache: true,
            seed: isMobile ? false : 30 * 1024 * 1024,
          },
          (downloadProgress) => (this.downloadProgress = downloadProgress)
        );
        const blobUrl = URL.createObjectURL(blob);
        this.emitUrl(blobUrl);
      } catch (e) {
        this.logger.error(
          "Failed to fetch binary using WebTorrent, fallback to fetch()"
        );
        this.emitUrl(getOriginalWebUrl(this.bucket, this.objectKey));
      }
      await this.$nextTick();
    },
    emitUrl: function (url) {
      this.$emit("url", url);
    }
  },
};
</script>
