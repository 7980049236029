<template>
  <div id="games">
    <b-container class="mt-3">
      <b-row>
        <b-col sm="8">
          <EntryGameCard ref="gameCard" :game_info="game_info" />
        </b-col>

        <!-- Sidebar column -->
        <b-col sm="4">
          <SidebarAd :identifier="identifier" />
          <CoverCard :game_info="game_info" />
          <KeymapsCard :game_info="game_info" />
          <CheatsCard :game_info="game_info" />
          <SavesCard
            :save_key="game_info.identifier"
            :dos_backend="dos_backend"
            v-if="dos_backend && dos_backend !== 'native_dosbox'"
          />
          <BackendCard
            :identifier="game_info.identifier"
            :dos_backend="dos_backend"
            v-if="dos_backend && dos_backend !== 'native_dosbox'"
          />
          <LinksCard :game_info="game_info" />
          <FeedbackCard />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import gameDataJson from "@/utils/info/gameDataJson";

import game_info_mixin from "@/mixins/game_info_mixin";

import { EntryGameCard } from "@/components/GameCard";

import {
  SidebarAd,
  CoverCard,
  KeymapsCard,
  CheatsCard,
  LinksCard,
  BackendCard,
  SavesCard,
  FeedbackCard,
} from "@/components/Sidebar";

export default {
  components: {
    EntryGameCard,
    SidebarAd,
    CoverCard,
    KeymapsCard,
    CheatsCard,
    LinksCard,
    SavesCard,
    FeedbackCard,
    BackendCard,
  },
  name: "game",
  mixins: [game_info_mixin],
  data() {
    return {
      isMounted: false,
    };
  },
  provide() {
    return {
      jsdos_save: this.save,
    };
  },
  computed: {
    game_info() {
      return gameDataJson[this.$route.params.identifier];
    },
    gameCard: function () {
      if (this.isMounted) {
        return this.$refs?.gameCard;
      }
      return undefined;
    },
    dos_backend: function () {
      return this.gameCard?.dos_backend;
    },
  },
  beforeMount() {
    if (!(this.$route.params.identifier in gameDataJson)) {
      this.$router.replace({ name: "error" });
    }
  },
  mounted: async function () {
    this.isMounted = true;
  },
  methods: {
    save: async function () {
      if (this?.gameCard?.save) {
        await this.gameCard.save();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.dos_backend != "native_dosbox") {
      (async () => {
        try {
          await this.gameCard.save();
        } catch (e) {
          console.error(e);
        } finally {
          window.location.href = to.fullPath;
        }
      })();
      return;
    } else {
      next();
    }
  },
  metaInfo() {
    return {
      title: this.name,
      meta: [
        {
          name: "description",
          content: `在浏览器中在线游玩${this.name}`,
        },
        {
          name: "keywords",
          content: `${this.name}, 在线, DOS, 游戏, 在线DOS游戏`,
        },
        {
          property: "og:title",
          content: `${this.name} - 在线 DOS 游戏`,
        },
        {
          property: "og:url",
          content: this.game_web_url,
        },
        {
          property: "og:description",
          content: `在浏览器中在线游玩${this.name}`,
        },
      ],
      link: [{ rel: "canonical", href: this.game_web_url }],
      script: [
        {
          type: "application/ld+json",
          json: [
            this.structured_data,
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "游戏列表",
                  item: "https://dos.zczc.cz/games/",
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: this.name,
                  item: this.game_web_url,
                },
              ],
            },
          ],
        },
      ],
    };
  },
};
</script>
