<template>
  <div id="games">
    <b-container class="mt-3">
      <b-row>
        <b-col sm="8">
          <custom-game-card
            :binary_url="processed_binary_url"
            :drive_type="drive_type"
            :save_key="save_key"
            :executable="executable"
            :ready="ready"
          />
        </b-col>
        <b-col sm="4">
          <!-- TODO: AD -->
          <b-card class="mb-3" title="测试版警告⚠️" title-tag="h5">
            <b-card-text>
              此功能通过代理进行反代，有可能无法获取某些网址，且大文件极容易失败。
            </b-card-text>
          </b-card>

          <b-card
            class="mb-3"
            id="emulatorInfoInput"
            title="参数设置"
            title-tag="h5"
          >
            <div class="card-text">
              <form>
                <b-form-group label="文件地址">
                  <b-form-input
                    v-model="binary_url"
                    placeholder="https://example.com/game.zip"
                    :disabled="ready"
                    required
                  />
                  <small id="gameFileHelp" class="form-text text-muted">
                    请填写 Zip 文件链接，支持 HTTP 与 HTTPS，可上传至如
                    <a
                      href="https://catbox.moe/"
                      target="_blank"
                      rel="noopener"
                    >
                      Catbox
                    </a>
                    的直链网盘。
                  </small>
                </b-form-group>

                <b-form-group
                  label="映像类型"
                  description="一般默认即可，详见 https://www.dosbox.com/wiki/MOUNT"
                >
                  <b-form-select
                    v-model="drive_type"
                    :options="drive_type_options"
                    :disabled="ready"
                  />
                </b-form-group>

                <b-form-group
                  label="执行命令"
                  description="运行后执行命令，可置空。"
                >
                  <b-form-input v-model="executable" :disabled="ready" />
                </b-form-group>

                <b-form-group
                  label="存档名"
                  description="存档的名称，置空将随机生成。"
                >
                  <b-form-input v-model="save_key" :disabled="ready" />
                </b-form-group>

                <b-button
                  v-if="!ready"
                  variant="primary"
                  @click="submitCustomGame"
                >
                  启动
                </b-button>
              </form>
            </div>
          </b-card>
          <FeedbackCard />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { FeedbackCard } from "@/components/Sidebar";

import CustomGameCard from "./components/CustomGameCard.vue";
import { BFormSelect, BFormGroup } from "bootstrap-vue";

const PROXY_ZIP_URL = "https://dos.zczc.men/image.zip?";

export default {
  name: "custom",
  metaInfo() {
    return {
      title: "自定义游戏（测试版）",
      meta: [
        { name: "description", content: "在浏览器中在线游玩自定义 DOS 游戏" },
        {
          name: "keywords",
          content: "自定义DOS游戏, 在线, DOS, 游戏, 在线DOS游戏",
        },
        { property: "og:title", content: "自定义游戏 - 在线 DOS 游戏" },
        { property: "og:url", content: "https://dos.zczc.cz/games/custom/" },
        {
          property: "og:description",
          content: "在浏览器中在线游玩自定义 DOS 游戏",
        },
        { name: "robots", content: "noindex" },
      ],
    };
  },
  data: function () {
    return {
      binary_url: this.$route.query.binary_url ?? "",
      processed_binary_url: null,
      drive_type: this.$route.query.drive_type ?? "hdd",
      executable: this.$route.query.executable ?? "",
      save_key: this.$route.query.save_key ?? "",
      ready: false,
    };
  },
  components: {
    FeedbackCard,
    CustomGameCard,
    BFormSelect,
    BFormGroup,
  },
  mounted() {
    if (this.$route.query.binary_url) {
      const vue_instance = this;
      (async function () {
        await vue_instance.processBinaryUrl();
        vue_instance.ready = true;
      })();
    }
  },
  methods: {
    submitCustomGame: async function () {
      // Check binary url
      if (this.binary_url === "") {
        this.$bvToast.toast(`请在输入框中输入游戏 Zip 文件链接`, {
          title: "参数错误❌",
          autoHideDelay: 5000,
        });
        return;
      }

      const expression = /(http|https):\/\/.*/gi;
      const regex = new RegExp(expression);
      if (!this.binary_url.match(regex)) {
        this.$bvToast.toast(`请输入正确的游戏 Zip 文件链接`, {
          title: "链接错误❌",
          autoHideDelay: 5000,
        });
        return;
      }

      // generate random save name
      if (!this.save_key) {
        this.save_key = Math.random().toString(36).substring(7);
      }

      await this.processBinaryUrl();
      this.ready = true;
      this.changeBroswerUrl();
    },
    changeBroswerUrl() {
      const params = {
        binary_url: this.binary_url,
        drive_type: this.drive_type,
        executable: this.executable,
        save_key: this.save_key,
      };

      // add url to browser history
      const esc = encodeURIComponent;
      const query = Object.keys(params)
        .map((k) => esc(k) + "=" + esc(params[k]))
        .join("&");
      window.history.pushState(
        params,
        "Custom DOS Game for " + this.binary_url,
        window.location.pathname + "?" + query
      );
    },
    processBinaryUrl: async function () {
      // Check CORS
      try {
        const response = await fetch(this.binary_url, {
          method: "HEAD",
        });
        this.processed_binary_url = response.ok
          ? this.binary_url
          : this.proxy_url;
      } catch (e) {
        this.processed_binary_url = this.proxy_url;
      }
    },
  },
  computed: {
    proxy_url() {
      const url_escaped = encodeURIComponent(this.binary_url);
      const proxy_url = `${PROXY_ZIP_URL}url=${url_escaped}`;
      return proxy_url;
    },
    drive_type_options: () => [
      { value: "hdd", text: "HDD" },
      { value: "cdrom", text: "CD-ROM" },
      { value: "floppy", text: "Floppy" },
    ],
  },
};
</script>
