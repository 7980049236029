<template>
  <span
    @click="$emit('click')"
    tabindex="0"
    role="button"
  >
    <maximize-2-icon id="tooltip-maximize2" />
    <b-tooltip target="tooltip-maximize2" triggers="hover">
      全屏游戏
    </b-tooltip>
  </span>
</template>

<script>
import { Maximize2Icon } from "vue-feather-icons";
import { BTooltip } from "bootstrap-vue";

export default {
  name: "FullscreenControl",
  components: {
    Maximize2Icon,
    BTooltip,
  },
};
</script>
