<template>
  <span>
    <a :href="href" target="_blank">
      <info-icon id="tooltip-info" />
      <b-tooltip target="tooltip-info" triggers="hover"> 游戏信息 </b-tooltip>
    </a>
  </span>
</template>

<script>
import { InfoIcon } from "vue-feather-icons";
import { BTooltip } from "bootstrap-vue";

export default {
  name: "InfoControl",
  components: {
    InfoIcon,
    BTooltip,
  },
  props: ["href"],
};
</script>

<style scoped>
a {
  color: inherit;
}

a:hover {
  color: inherit;
}
</style>