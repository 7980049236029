<template>
  <span @click="$emit('click')" tabindex="0" role="button">
    <BIconKeyboard  id="tooltip-command" class="keyboard-control" />
    <b-tooltip target="tooltip-command" triggers="hover"> 开关键盘 </b-tooltip>
  </span>
</template>

<script>
import { BTooltip, BIconKeyboard } from "bootstrap-vue";

export default {
  name: "KeyboardControl",
  components: {
    BTooltip,
    BIconKeyboard,
  },
  mounted: function () {
    this.showKeyboardHint();
  },
  methods: {
    showKeyboardHint: function () {
      if (/Android|iPhone|iPad|iPod|IEMobile/i.test(navigator.userAgent)) {
        this.$bvToast.toast(
          `新增虚拟键盘功能，请点击控制区中的键盘图标进行测试。`,
          {
            title: "虚拟键盘 ⌨",
            toaster: "b-toaster-bottom-full",
            solid: true,
            autoHideDelay: 5000,
          }
        );
      }
    },
  },
};
</script>

<style scoped>
.keyboard-control {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}
</style>