<template>
  <div>
    <JsDosGameCard
      ref="jsDosGameCard"
      :game_info="game_info"
      v-if="dos_backend === 'jsdos'"
    />
    <EmularityGameCard
      :game_info="game_info"
      v-if="dos_backend === 'emularity'"
    />
    <NativeDOSBoxGameCard
      :game_info="game_info"
      v-if="dos_backend === 'native_dosbox'"
    />
  </div>
</template>

<script>
import { getBackend } from "@/utils/backend";

import game_info_mixin from "@/mixins/game_info_mixin";

import { EmularityGameCard, JsDosGameCard } from "@/components/GameCard";

import { getLogger } from "@/utils/log";

export default {
  components: {
    EmularityGameCard,
    JsDosGameCard,
    NativeDOSBoxGameCard: () =>
      import(
        /* webpackChunkName: "Electron" */ "./NativeDOSBoxGameCard/index.vue"
      ),
  },
  props: ["game_info"],
  name: "EntryGameCard",
  mixins: [game_info_mixin],
  data: function () {
    return {
      dos_backend: undefined,
    };
  },
  methods: {
    save: async function () {
      if (this.dos_backend === "jsdos") {
        if (this?.$refs?.jsDosGameCard?.controls?.save) {
          await this.$refs.jsDosGameCard.controls?.save();
        }
      }
    },
  },
  mounted: async function () {
    this.dos_backend = await getBackend(this.identifier);
    getLogger(["backend"]).log(`Dos backend: ${this.dos_backend}`);
  },
};
</script>
