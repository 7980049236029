<template>
  <span>
    <a :href="href" target="_blank">
      <download-icon id="tooltip-download" />
      <b-tooltip target="tooltip-download" triggers="hover">
        下载游戏
      </b-tooltip>
    </a>
  </span>
</template>

<script>
import { DownloadIcon } from "vue-feather-icons";
import { BTooltip } from "bootstrap-vue";

export default {
  name: "DownloadControl",
  components: {
    DownloadIcon,
    BTooltip,
  },
  props: ["href"],
};
</script>

<style scoped>
a {
  color: inherit;
}

a:hover {
  color: inherit;
}
</style>