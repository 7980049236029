export default function () {
  window.addEventListener(
    "keydown",
    function (e) {
      if (
        [
          "Space",
          "Enter",
          "ControlLeft",
          "Tab",
          "AltLeft",
          "ShiftLeft",
          "F1",
          "F2",
          "F3",
          "F4",
          "F5",
          "F6",
          "F7",
          "F8",
          "F9",
          "F10",
          "F11",
          "F12",
          "Escape",
        ].indexOf(e.code) > -1
      ) {
        e.preventDefault();
      }
    },
    false
  );
}
