<template>
  <div class="console">
    <div class="header">
      <span class="header-text">DOSBox 日志</span>
    </div>
    <p>
      <span v-for="(log, idx) in sliceLogs" :key="idx">
        <pre v-if="log.type == 'stdout'" class="shell stdout">{{
          log.message
        }}</pre>
        <pre v-if="log.type == 'stderr'" class="shell stderr">{{
          log.message
        }}</pre>
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: "ConsoleOutput",
  props: ["logs"],
  computed: {
    sliceLogs: function () {
      return this.logs.slice(-5);
    },
  },
};
</script>

<style scoped>
.console {
  background: black;
  font-family: monospace;
  width: 100%;
  padding: 1rem;
}

.header {
  background-size: contain;
  background-image: url("~@/assets/img/emularity_splash_image.svg");
  background-position: left;
  background-repeat: no-repeat;
  background-origin: content-box;
  margin-bottom: 0.5em;
  width: 100%;
  height: 2em;
}

.header-text {
  color: white;
  padding-left: 3em;
  line-height: 2em;
  font-family: sans-serif;
}

.shell {
  word-break: break-all;
  white-space: pre-wrap;
  display: inline;
}
.stdout {
  color: white !important;
}
.stderr {
  color: red !important;
}
</style>