<template>
  <b-card class="mb-3" no-body>
    <slot name="canvas" />

    <!-- Game title and controls -->
    <b-card-body>
      <!-- Title -->
      <h5 class="game-title">{{ name }}</h5>
      <!-- Controls -->
      <div class="card-controls">
        <slot name="controls" />
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import game_info_mixin from "@/mixins/game_info_mixin";

export default {
  name: "GameCardTemplate",
  props: ["game_info"],
  mixins: [game_info_mixin],
};
</script>

<style scoped>
.game-title {
  display: inline-block;
}

.card-controls {
  float: right;
  vertical-align: top;
}
</style>

<style lang="scss" scoped>
.card-controls {
  float: right;
  vertical-align: top;

  & > * {
    margin-left: 0.3rem;
  }
}
</style>
