<template>
  <div class="click-to-run mb-2" role="button" @click="$emit('click')">
    <BIconPlayCircleFill /><span class="ml-1">点击启动游戏</span>
  </div>
</template>

<script>
import { BIconPlayCircleFill } from "bootstrap-vue";

export default {
  name: "ClickToRun",
  components: {
    BIconPlayCircleFill,
  },
};
</script>

<style scoped>
.click-to-run {
  color: white;
  text-align: center;
}
</style>