<template>
  <div>
    <div
      id="screen_container"
      v-bind:class="{
        'html-fullscreen': isFullscreen,
        'html-fullscreen-keyboard': showKeyboard && isFullscreen,
      }"
    >
      <canvas class="game-canvas" id="canvas" oncontextmenu="event.preventDefault()" />
    </div>
  </div>
</template>

<script>
import {
  dosbox_js_url,
  dosbox_mem_url,
  startUpEmulator,
} from "@/utils/emularity";

export default {
  data: function () {
    return {
      isRunning: false,
    };
  },
  mounted: function () {
    if (navigator.userAgent.includes("prerender")) {
      return;
    }
    this.startUpEmulator();
  },
  computed: {
    debug: function () {
      return this.$route.query?.debug == "true" ? true : false;
    },
  },
  name: "EmularityPlayer",
  props: [
    "binary_url",
    "drive_type",
    "save_key",
    "executable",
    "img",
    "isFullscreen",
    "showKeyboard",
  ],
  methods: {
    startUpEmulator: async function () {
      const dom = document.getElementById("canvas");
      this.emulator = await startUpEmulator(dom, {
        binary_url: this.binary_url,
        drive_type: this.drive_type,
        save_key: this.save_key,
        executable: this.executable,
        img: this.img,
        debug: this.debug,
      });

      this.isRunning = true;
    },
  },
  metaInfo() {
    return {
      link: [
        { rel: "preload", href: dosbox_js_url, as: "script" },
        { rel: "preload", href: dosbox_mem_url, as: "script" },
      ],
    };
  },
};
</script>

<style scoped>
.html-fullscreen {
  position: fixed;
  left: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
  z-index: 2000;
  object-fit: contain;
}

.html-fullscreen-keyboard {
  bottom: 275px !important;
  height: calc(100% - (275px)) !important;
}
</style>

<style lang="scss">
$background-color: #222;

#screen_container {
  .game-canvas {
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border: 0px none;
    background-color: $background-color;
    width: 100%;
    height: 100%;
    object-fit: contain;
    image-rendering: auto !important;
  }

  .emularity-splash-screen {
    position: relative !important;
    background-color: $background-color !important;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .emularity-progress-indicator {
    background-color: $background-color !important;
  }
}
</style>