<template>
  <div
    v-show="isFullscreen"
    class="exit-fullscreen"
    v-bind:class="{
      'exit-fullscreen-keyboard': showKeyboard,
    }"
  >
    <b-button pill variant="light" @click="$emit('click')">
      <BIconFullscreenExit />
    </b-button>
  </div>
</template>

<script>
import { BIconFullscreenExit, BButton } from "bootstrap-vue";

export default {
  name: "ExitFullscreenButton",
  props: ["isFullscreen", "showKeyboard"],
  components: {
    BIconFullscreenExit,
    BButton,
  },
};
</script>

<style scoped lang="scss">
.exit-fullscreen {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  opacity: 0.1;
  z-index: 3000;

  &:hover {
    opacity: 1;
  }

  &-keyboard {
    bottom: calc(1rem + (275px)) !important;
  }
}
</style>